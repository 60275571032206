import authClient from '@/utils/auth-client'
import router from "@/router";
import {NewPasswordRequest, PasswordResetRequest, StatusResponse} from "@/model/models";
import apiClient from "@/utils/api-client";

class AuthService {
  public signIn(email: string, password: string) {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')

    return new Promise<boolean>((resolve, reject) => {
      const requestBody = 'grant_type=password&scope=API&username=' + encodeURIComponent(email) + '&password=' + encodeURIComponent(password)

      authClient.post('/token', requestBody).then((res) => {
        const token = res.data.access_token
        const expiresIn = (res.data.expires_in | 0) * 1000

        if (token && token.length && expiresIn > 0) {
          const expirationDate = new Date((new Date()).getTime() + expiresIn)

          localStorage.setItem('access_token', token)
          localStorage.setItem('refresh_token', res.data.refresh_token)

          resolve(true)
        } else {
          reject(new Error('Invalid access token'))
        }
      }).catch((error) => reject(error))
    })
  }

  public initPasswordReset(passwordResetRequest: PasswordResetRequest) {
    return new Promise<StatusResponse>((resolve, reject) => {
      apiClient.post('/api/v1/auth/init-reset', passwordResetRequest).then((res) => {
        if (res.status === 200 && res.data !== null) {
          resolve(new StatusResponse(res.data.code, res.data.userId, res.data.message, res.data.timeStamp))
        } else {
          reject(new Error(res.data.message))
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  public resetPassword(newPasswordRequest: NewPasswordRequest) {
    return new Promise<StatusResponse>((resolve, reject) => {
      apiClient.post('/api/v1/auth/reset-password', newPasswordRequest).then((res) => {
        if (res.status === 200 && res.data !== null) {
          resolve(new StatusResponse(res.data.code, res.data.userId, res.data.message, res.data.timeStamp))
        } else if (res.status === 208) {
          reject(new Error("The link is expired, or you already reset your password. Please request a new password reset."))
        } else {
          reject(new Error("An error occurred. Please try again later."))
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  public refreshToken() {
    return new Promise<boolean>((resolve, reject) => {
      const requestBody = 'grant_type=refresh_token&refresh_token=' + localStorage.getItem('refresh_token')

      authClient.post('/token', requestBody).then((res) => {
        const token = res.data.access_token
        const expiresIn = (res.data.expires_in | 0) * 1000

        if (token && token.length && expiresIn > 0) {
          const expirationDate = new Date((new Date()).getTime() + expiresIn)

          localStorage.setItem('access_token', token)
          localStorage.setItem('refresh_token', res.data.refresh_token)

          resolve(true)
        }
      }).catch(() => {
          localStorage.clear()
          localStorage.setItem('sessionExpired', "true")
          router.push('/signin')
      })
    })
  }

  public signOut() {
    return new Promise<boolean>((resolve) => {
      localStorage.clear()
      resolve(true)
    })
  }

  public isSignedIn() : boolean {
    if (!localStorage.getItem("forwarded") && window.location.search.includes("access_token")) {
      localStorage.setItem('forwarded', document.referrer)//in case a user is forwarded for another reason, (add on different website)
      const urlParams = new URLSearchParams(window.location.search);
      const incomingToken = urlParams.get('access_token');
      if (incomingToken) localStorage.setItem('access_token', incomingToken)
    }
    const token = localStorage.getItem('access_token')

    return token !== null && token.length > 0
  }

  // public getUserName() : string | null {
  //   return localStorage.getItem('user_name')
  // }
}

export default new AuthService()
